import React from "react"
import PropTypes from "prop-types"
// Components
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
import { SlugLink } from '../components/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Tags = ({ location, pageContext, data }) => {
  //console.log(pageContext, data);
  const siteTitle = data.site.siteMetadata.title
  const shortName = data.site.siteMetadata.shortName
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  let fullTag = tag;
      const siteUrl = data.site.siteMetadata.siteUrl
 // const siteUrl = 'http://localhost:8000'
  if (edges && edges.length > 0) {
    fullTag = edges[0].node.frontmatter.tags.filter(x => SlugLink(x) === tag);
  }
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"
    } "${fullTag}"`
  if (location != null) {
    if (location.href != null && location.href.endsWith('/')) {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }
  }

  return (
    <Layout location={location} title={`${siteTitle}`}>
      <SEOTAG
        title={`${fullTag} - ${shortName}`}
        description={`${fullTag} - ${siteTitle}`}
      // keywords={[`${fullTag}`]}
      />
      <div>
        <div className="row">
            <header className="tb-header col-md-10 m-auto text-center">
                <h1 className="w-page-header__headline">{tagHeader}</h1>
                <p className="w-page-header__copy"></p>
              </header>
        </div>
        <div className="row">
          {edges.map(({ node }) => {
            let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid.src
            const title = node.frontmatter.title || node.fields.slug
            return (

              <div className="col-md-6">
                <article class="archive-post mts-post" key={node.fields.slug}>
                  <a href={siteUrl + node.fields.slug} title={title} class="post-image post-image-full">
                    <div class="featured-thumbnail">
                      <LazyLoadImage
                        className="wp-post-image"
                        alt={title}
                        title={title}
                        src={featuredImgFluid}
                        effect="blur"
                      />
                    </div>
                  </a>
                  <div class="post-content">
                    <div class="post-meta">
                      <span class="thecategory">
                        {node.frontmatter.category !== null ? <a style={{ color: 'rgba(0, 0, 0, .44)', }} href={siteUrl + `/category/${SlugLink(node.frontmatter.category)}`}>{node.frontmatter.category}</a> : null}
                      </span>
                      <span class="thetime updated">{node.frontmatter.date}</span>
                    </div>
                    <header>
                      <h2 class="post-title">
                        <a href={siteUrl + node.fields.slug} title={title}>{title}</a></h2>
                    </header>
                    <div class="post-excerpt">
                      <p className="tb-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                      <a href={siteUrl + node.fields.slug} title={title} class="read-more-link">Read More</a>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        <div className="row pagination">
          <ul className="col d-flex justify-content-center">
            <li className="tb-pagination__link">
              <a className="current" href="/tags" style={{width: '100px'}}>
                Tất Cả
              </a>
            </li>
          </ul> 
        </div>
      </div>
    </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        domainName
        shortName
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { fields: { tags: { in: [$tag] } } }
    ) {
      totalCount
      
      edges {
        node {
          fields {
            slug
            tags
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`
export default Tags